import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import { getDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

import "./App.css";

function App() {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [streamingUrl, setStreamingUrl] = useState("");
  const [loading, setLoading] = useState(true); // Added loading state
  const [error, setError] = useState(null); // Added error state

  useEffect(() => {
    getCurrentStreamingUrl();
  }, []);

  const handlePlayPause = () => {
    if (audioRef.current && streamingUrl) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch((error) => {
          console.error("Failed to play audio:", error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Fetch the current streamingUrl from Firestore
  const getCurrentStreamingUrl = async () => {
    try {
      const settingsRef = doc(db, "settings", "radioSettings");
      const settingsDoc = await getDoc(settingsRef);
      if (settingsDoc.exists()) {
        const currentStreamingUrl = settingsDoc.data().streamingUrl;
        setStreamingUrl(currentStreamingUrl);
      } else {
        console.warn("No settings document found");
      }
    } catch (err) {
      console.error("Error fetching streaming URL:", err);
      setError("Failed to load streaming URL.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="box">
          <img className="img" src="hfm-logo.png" alt="FM Radio" height={120} />
          <button
            className="button"
            onClick={handlePlayPause}
            disabled={loading}
          >
            {loading ? (
              <ReactLoading type="spin" color="#000" height={50} width={50} />
            ) : (
              <FontAwesomeIcon icon={isPlaying ? faStop : faPlay} size="3x" />
            )}
          </button>
        </div>
        {error && <p className="error-message">{error}</p>}
        <audio ref={audioRef} src={streamingUrl} />
      </header>
      <Analytics />
      <SpeedInsights />
    </div>
  );
}

export default App;
